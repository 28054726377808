<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro11.png" alt="" />
    <div class="yj">
      <h4><span></span>产业投资</h4>
      <p>集团投资重点布局互联网医疗、健康管理咨询、医疗产品研发生产、综合医美服务、功能营养食品等投资领域，打造大健康全产业链投资 平台和专业化产业服务平台。集团紧跟国家发展战略与规划，努力将以区域为单位的行业生态圈打造成全国医疗联合体，实现医疗资源领 域的国民共同富裕。</p>
      <p>目前集团与多家银行，投融资机构达成深度合作，并成功投资了中科医创科技有限公司，中鑫广汇医疗科技（上海）有限公司等企业，两 家公司在2年内均已成为资产规模千万级别、利润规模稳定在5000万元左右的医疗健康行业中的优质企业。</p>
    </div>
  </div>
  <div class="main" v-else>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.51rem;
        border-top: 0.3rem solid #e5e5e5;
        border-bottom: 0.4rem solid #e5e5e5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.1rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        p{
            width: 6.8rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
        }
        :nth-child(2){
            margin-bottom:0.2rem
        }
      }
      
    }
  }
  @media screen and (min-width: 960px) { 
    
  }
</style>